/** @jsx jsx */
import * as React from 'react'
import { Grid, Text, Heading, Spinner, jsx } from 'theme-ui'
import { RouteComponentProps } from '@reach/router'
import { alpha } from '@theme-ui/color'
import format from 'date-fns/format'
import differenceInMinutes from 'date-fns/differenceInMinutes'

import { Layout } from '@components/Layout'
import { Button } from '@components/Button'
import { LoginForm } from '@components/LoginForm'

export const Admin = (props: RouteComponentProps) => {
  const [authenticated, setAuthenticated] = React.useState(false)
  const [data, setData] = React.useState()
  const [loading, setLoading] = React.useState(true)

  const fetchData = React.useCallback(async () => {
    setLoading(true)
    try {
      const response = await fetch('/api/stats')
      const data = await response.json()
      setData(data)
      setLoading(false)
    } catch (e) {
      console.warn(e)
      setLoading(false)
    }
  }, [])

  React.useEffect(() => {
    fetchData()
  }, [authenticated])

  return (
    <Layout>
      <Grid
        sx={{
          maxWidth: 1200,
          mx: 'auto',
          p: 6,
          gridColumnGap: 5,
          gridTemplateColumns: 'max-content 1fr',
          gridTemplateRows: 'max-content 1fr',
          gridTemplateAreas: `'heading heading' 'sidebar rooms'`,
          color: 'black'
        }}
      >
        <Heading sx={{ gridArea: 'heading' }}>Dashboard</Heading>
        <Grid
          gap={5}
          sx={{
            alignItems: 'flex-start',
            gridArea: 'sidebar',
            gridTemplateRows: 'max-content'
          }}
        >
          <Button variant="dark" onClick={fetchData}>
            Refetch data
          </Button>
          <LoginForm
            setAuthenticated={(authenticated: boolean) =>
              setAuthenticated(authenticated)
            }
          />
        </Grid>
        <Grid
          gap={3}
          sx={{
            alignItems: 'flex-start',
            gridTemplateRows: 'max-content',
            gridArea: 'rooms'
          }}
        >
          {loading ? (
            <Spinner />
          ) : !data ? (
            <Text>Not authenticated.</Text>
          ) : (
            data.rooms.map((room, i) => (
              <Grid
                key={`room-${i}-${room.id}`}
                gap={2}
                sx={{
                  borderRadius: 3,
                  bg: room.deletedAt ? alpha('grays.100', 0.25) : 'grays.100',
                  p: 3
                }}
              >
                <Text>{room.id}</Text>
                <Text variant="small" color="grays.300">
                  Created on{' '}
                  {format(new Date(room.createdAt), 'EEEE MMMM do @ HH:mm')}
                  {!!room.deletedAt && (
                    <React.Fragment>
                      {` – Duration: ${differenceInMinutes(
                        new Date(room.deletedAt),
                        new Date(room.createdAt)
                      )} min`}
                    </React.Fragment>
                  )}
                </Text>
                <Grid sx={{ justifyContent: 'flex-start' }}>
                  {room.clients.length === 0
                    ? 'No connected clients.'
                    : room.clients.map((client, j) => (
                        <Grid
                          key={`room-${i}-client-${j}`}
                          sx={{
                            alignItems: 'center',
                            p: 2,
                            borderRadius: 4,
                            gridColumnGap: 1,
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderColor: 'black',
                            gridTemplateColumns: 'max-content 1fr max-content'
                          }}
                        >
                          <Text>{client.id}</Text>
                        </Grid>
                      ))}
                </Grid>
              </Grid>
            ))
          )}
        </Grid>
      </Grid>
    </Layout>
  )
}
