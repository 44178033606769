/** @jsx jsx */
import { jsx, Text, Label } from 'theme-ui'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'

import { Button } from '@components/Button'
import { Input } from '@components/Input'

const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required()
})

export const LoginForm = ({
  setAuthenticated
}: {
  setAuthenticated: (authenticated: boolean) => void
}) => {
  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={loginSchema}
      initialStatus={{}}
      onSubmit={async (values, { setStatus }) => {
        try {
          const response = await fetch('/api/authenticate', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
              'Content-Type': 'application/json'
            }
          })
          if (response.status === 200) {
            setAuthenticated(true)
          } else {
            throw new Error(response.error || 'Inloggen mislukt.')
          }
        } catch (e) {
          setAuthenticated(false)
          setStatus({
            error: 'Inloggen mislukt.'
          })
        }
      }}
    >
      {({ isSubmitting, status }) => (
        <Form
          sx={{
            display: 'grid',
            gridRowGap: 2
          }}
        >
          {status.error && <Text color="error">{status.error}</Text>}
          <Label htmlFor="email">E-mail address</Label>
          <Field name="email" component={Input} />
          <Label htmlFor="email">Password</Label>
          <Field name="password" type="password" component={Input} />
          <Button type="submit" disabled={isSubmitting}>
            Login
          </Button>
        </Form>
      )}
    </Formik>
  )
}
